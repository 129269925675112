const removeKeywords = [
  "www.",
  "localhost.",
  ".io",
  ".com",
  "crm.",
  "https://",
  "http://",
  "local.",
  "staging.",
  "signature.",
];

const host = window.location.hostname.toLowerCase();

const siteName = removeKeywords.reduce((acc, keyword) => {
  return acc.replace(keyword, "");
}, host);

const SITE_SETTINGS = {
  caronecloud: {
    siteName: siteName,
    api: {
      production: "https://api.warbox.io/api/v1",
      staging: "https://staging.api.warbox.io/api/v1",
      development: "http://localhost:5000/api/v1",
    },
    client: "carone",
  },
  warbox: {
    siteName: siteName,
    api: {
      production: "https://warbox-api-production.up.railway.app/api/v1",
      staging: "https://staging.api.warbox.io/api/v1",
      development: "http://localhost:5000/api/v1",
    },
    client: "warbox",
  },
  "demo.warbox": {
    siteName: siteName,
    api: {
      production: "https://api.warbox.io/api/v1",
      staging: "https://staging.api.warbox.io/api/v1",
      development: "http://localhost:5000/api/v1",
    },
    client: "demo",
  },
  default: {
    siteName: siteName,
    api: {
      production: "https://api.warbox.io/api/v1",
      staging: "https://staging.api.warbox.io/api/v1",
      development: "http://localhost:5000/api/v1",
    },
    client: "demo",
  },
};

export default SITE_SETTINGS[siteName]
  ? SITE_SETTINGS[siteName]
  : SITE_SETTINGS["default"];
