import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material'

const MainLayout = () => {

  const styles = {
    wrapper: {
      backgroundColor: '#E6E6EC',
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: 'url(https://flevix.com/wp-content/uploads/2019/12/Live-Background-1.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '150%',
      margin: -10,
      minHeight: '101vh'
    },
    content: {
      padding: '2em',
      paddingBottom: '15em'
    }
  }

  return (
    <Box style={styles.wrapper}>
      <Box sx={{ padding: '2em', paddingBottom: { xs: '15em', sm: '2em' } }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
