import SITE_SETTINGS from "./whiteLabel";

export const HEADERS = () => {
  let headers = {
    "Content-Type": "application/json",
    "Client-Name": SITE_SETTINGS.client,
  };

  return {
    headers,
  };
};
